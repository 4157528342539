import {formatNumber} from 'Helpers/number'
import {APPROVED, PAYMENT_METHODS, UNPAID} from 'Helpers/options'
import {TruckingOrderRQ} from 'Hooks/Queries'
import {useEffect, useState} from 'react'
import {
  Link,
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import ImageTextPreview from 'Components/Common/ImageTextPreview'
import OpenMap from 'Components/Map/Map'
import {
  CreateLeafIcon,
  RoutingAdd,
  degToRad,
  getBearing,
  radToDeg,
} from 'Components/Map/MapRouting'
import {Marker} from 'react-leaflet'

export default function DetailTruckingOrder(props) {
  const {id} = useParams()
  const [total, setTotal] = useState(0)
  const navigate = useNavigate()
  const [refetchInterval, setRefetchInterval] = useState(1000 * 60)
  const {data, error, isLoading, isFetching, status} =
    TruckingOrderRQ.useGetData(id)(refetchInterval)
  const [track, setTrack] = useState()
  const {price, final_price, additional_costs, ppn} =
    data?.data?.price_detail || {}

  useEffect(() => {
    if (!isLoading && status === 'success') {
      let total = price
      additional_costs?.forEach((v) => {
        total += v.value || 0
      })
      setTotal(total)
    }
    if (!isLoading && status === 'error') {
      console.log(error)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isLoading])

  const {
    origin_longitude,
    destination_longitude,
    origin_latitude,
    destination_latitude,
    tracking,
    last_log_status,
  } = data?.data ?? {}

  const {latitude, longitude} = tracking ?? {}

  const [waypoints, setWayPoints] = useState([])
  const [rotationAngle, setRotationAngle] = useState(0)
  const [center, setCenter] = useState([-6.2, 106.816666])

  const getMidPoint = (lat1, lon1, lat2, lon2) => {
    const dLon = degToRad(lon2 - lon1)

    //convert to radians
    lat1 = degToRad(lat1)
    lat2 = degToRad(lat2)
    lon1 = degToRad(lon1)

    const Bx = Math.cos(lat2) * Math.cos(dLon)
    const By = Math.cos(lat2) * Math.sin(dLon)
    const lat3 = Math.atan2(
      Math.sin(lat1) + Math.sin(lat2),
      Math.sqrt((Math.cos(lat1) + Bx) * (Math.cos(lat1) + Bx) + By * By),
    )
    const lon3 = lon1 + Math.atan2(By, Math.cos(lat1) + Bx)

    //print out in degrees
    return [radToDeg(lat3), radToDeg(lon3)]
  }

  useEffect(() => {
    if (!isLoading && status === 'success') {
      const bearing = getBearing(
        {lat: origin_latitude, long: origin_longitude},
        {lat: destination_latitude, long: destination_longitude},
      )
      setRotationAngle(bearing)

      const midPoint = getMidPoint(
        origin_latitude,
        origin_longitude,
        destination_latitude,
        destination_longitude,
      )
      setCenter(midPoint)
    }
  }, [status, isLoading])

  //refetch will affect car position only
  useEffect(() => {
    if (last_log_status === 'Done' && !isFetching) setRefetchInterval(false)
  }, [last_log_status, isFetching])

  useEffect(() => {
    if (!isFetching){
      
      setWayPoints([
        {lat: origin_latitude, long: origin_longitude},
        {lat:latitude, long:longitude},
        {lat: destination_latitude, long: destination_longitude},
      ])
      
      if(latitude && longitude) setTrack([latitude, longitude])
    }
  }, [latitude, longitude, isFetching])

  const isDriverAssignable =
    (data?.data?.driver_validation_status !== APPROVED &&
      data?.data?.payment_status !== UNPAID &&
      data?.data?.payment_method === PAYMENT_METHODS.TRANSFER) ||
    (data?.data?.driver_validation_status !== APPROVED &&
      (data?.data?.payment_method === PAYMENT_METHODS.BALANCE ||
        data?.data?.payment_method === PAYMENT_METHODS.AGREEMENT))

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Trucking Order' pageTitle='Trucking Order' />
        <Row>
          <Col lg={12}>
            <form onSubmit={() => {}}>
              <Card>
                <CardHeader className='d-flex justify-content-between align-items-center'>
                  <h4 className=''>Detail Trucking Order</h4>
                </CardHeader>
                <CardBody className='card-body'>
                  <div className='d-flex justify-content-between fw-bold'>
                    <h3 className='h4'>Nomor Order : {data?.data?.code}</h3>
                    <h3 className='h4'>CDO : {data?.data?.cdo?.code ?? '-'}</h3>
                  </div>
                  <hr />
                  <Row className='gy-4 mt-2'>
                    <Col lg={12}>
                      <div className='row g-3'>
                        <div className='col-md-12'>
                          <h5 className='fs-14 mb-0'>
                            <span>{data?.data?.trucking.name}</span>
                          </h5>
                        </div>

                        <div className='col-md-12'>
                          <h5 className='fs-14 mb-0'>
                            <span>{data?.data?.trucking.pic_email}</span>
                          </h5>
                        </div>

                        <div className='col-md-12'>
                          <h5 className='fs-14 mb-0'>
                            <span>{data?.data?.trucking.pic_phone}</span>
                          </h5>
                        </div>
                      </div>
                    </Col>
                    <hr />

                    <Col md={4} sm={12}>
                      <div className='col-md-12'>
                        <p className='text-muted mb-2 text-uppercase fw-semibold'>
                          Pengirim
                        </p>
                      </div>
                      <h5 className='fs-14 mb-2'>
                        <span>{data?.data?.sender_name}</span>
                      </h5>
                      <div className='mb-2'>
                        <h5 className='fs-14 mb-0'>
                          <span>{data?.data?.sender_address}</span>
                        </h5>
                      </div>
                      <div className='mb-2'>
                        <h5 className='fs-14 mb-0'>
                          <span>{data?.data?.sender_phone}</span>
                        </h5>
                      </div>
                    </Col>
                    <Col md={4} sm={12}>
                      <div className='mb-2'>
                        <p className='text-muted mb-2 text-uppercase fw-semibold'>
                          Penerima
                        </p>
                        <h5 className='fs-14 mb-0'>
                          <span>{data?.data?.receiver_name}</span>
                        </h5>
                      </div>
                      <div className='mb-2'>
                        {/* <p className="text-muted mb-2 text-uppercase fw-semibold">Pengirim</p> */}
                        <h5 className='fs-14 mb-0'>
                          <span>{data?.data?.receiver_address}</span>
                        </h5>
                      </div>
                      <div className='mb-2'>
                        {/* <p className="text-muted mb-2 text-uppercase fw-semibold">Invoice No</p> */}
                        <h5 className='fs-14 mb-0'>
                          <span>{data?.data?.receiver_phone}</span>
                        </h5>
                      </div>
                    </Col>

                    <Col md={4} sm={12}>
                      <div className='mb-2'>
                        <p className='text-muted mb-2 text-uppercase fw-semibold'>
                          Document
                        </p>
                      </div>
                      {data?.data?.files.map(({name, file}, i) => (
                        <div className='mb-2' key={i}>
                          <ImageTextPreview
                            link={file}
                            text={name}
                            className='fs-14 mb-0'
                          />
                        </div>
                      ))}
                    </Col>

                    <Col lg={12}>
                      <div className='table-responsive'>
                        <table className='table table-borderless text-center table-nowrap align-middle mb-0'>
                          <thead>
                            <tr className='table-active'>
                              <th scope='col'>ASAL</th>
                              <th scope='col'>TUJUAN</th>
                              <th scope='col'>JULMAH</th>
                              <th scope='col'>CAT</th>
                              <th scope='col'>TYPE</th>
                            </tr>
                          </thead>
                          <tbody id='products-list'>
                            <tr>
                              <td>{data?.data?.origin_point_name}</td>
                              <td>{data?.data?.destination_point_name}</td>
                              <td>{data?.data?.qty}</td>
                              <td>{data?.data?.truck_category.name}</td>
                              <td>{data?.data?.truck_type.name}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <hr className='my-3' />

                      <OpenMap center={center} zoom={1} height={400}>
                        <RoutingAdd waypoints={waypoints} />
                        {track ? (
                          <Marker
                            key={rotationAngle}
                            position={track}
                            icon={CreateLeafIcon({
                              url: '/assets/icons/car.png',
                            })}
                            rotationOrigin='center'
                            rotationAngle={rotationAngle}
                          />
                        ) : null}
                      </OpenMap>
                      <div className='border-top border-top-dashed mt-2'>
                        <table
                          className='table table-borderless table-nowrap align-middle mb-0 ms-auto'
                          style={{width: '250px'}}
                        >
                          <tbody>
                            <tr>
                              <td>Biaya Trucking</td>
                              <td className='text-end'>
                                {formatNumber(price)}
                              </td>
                            </tr>
                            {additional_costs?.map((p, i) => (
                              <tr key={i}>
                                <td>{p.name}</td>
                                <td className='text-end'>
                                  {formatNumber(p?.value)}
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td>Total</td>
                              <td className='text-end'>
                                {formatNumber(total)}
                              </td>
                            </tr>
                            <tr>
                              <td>PPN</td>
                              <td className='text-end'>
                                {formatNumber(ppn?.value)}
                              </td>
                            </tr>
                            <tr>
                              <td>Total Pembayaran</td>
                              <td className='text-end'>
                                {formatNumber(final_price)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </Row>
                </CardBody>

                <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
                  <button
                    className='btn btn-outline-dark'
                    onClick={() => navigate('/app/trucking-order')}
                  >
                    Tutup
                  </button>
                  {isDriverAssignable ? (
                    <Link
                      to={`/app/trucking-order/assign/${data?.data?.id}`}
                      className='btn btn-outline-dark'
                    >
                      Penugasan Driver
                    </Link>
                  ) : null}
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
