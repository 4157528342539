import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import {PAYMENT_METHODS, paymentMethods} from 'Helpers/options'
import {docTypes} from 'Pages/Cdo/Edit'
import flatpickr from 'flatpickr'
import React, {forwardRef, useEffect, useRef} from 'react'
import {Controller} from 'react-hook-form'
import Select from 'react-select'
import {Col, Input, Label, Row} from 'reactstrap'

const DataFormCDO = forwardRef((props, ref) => {
  const {
    errors,
    control,
    warehouseOptions,
    setSelectedWarehouse,
    selectedWarehouse,
    forwarderOptions,
    setSelectedForwarder,
    selectedForwarder,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    isSeachingNLE,
    onSearchNLE,
    docType,
    documentOptions,
    selectedDocument,
    setSelectedDocument,
    setPlpValues,
    isEdit
  } = props
  const {flyDateRef, kwitansiDateRef, plpDateRef, tglDocumentRef} = ref

  const izinBeacukaiInput = (
    <ErrorFieldWrapper error={errors.sppb} message={errors.sppb?.message}>
      <Label htmlFor='sppb' className='form-label text-uppercase'>
        {docType === docTypes.PLP ? 'No Plp' : ' IZIN BEACUKAI'}{' '}
        <span className='mandatory'>*</span>
      </Label>
      <div className='input-group'>
        <Controller
          id='sppb'
          name='sppb'
          type='sppb'
          control={control}
          render={({field}) => <Input {...field} value={field.value} />}
        />
        {typeof onSearchNLE === 'function' ? (
          <button
            className='btn btn-outline-primary'
            type='button'
            id='inputGroupFileAddon04'
            onClick={() => onSearchNLE()}
            disabled={isSeachingNLE}
            title='Cari dan otomatis input beberapa field'
          >
            <div className={isSeachingNLE ? 'rotate' : ''}>
              <i
                className={`ri ${
                  isSeachingNLE ? 'ri-loader-2-fill' : 'ri-search-line'
                }`}
              ></i>
            </div>
          </button>
        ) : null}
      </div>
    </ErrorFieldWrapper>
  )

  return (
    <Row className='gy-4 text-muted'>
      {docType === docTypes.SPPB && !isEdit? (
        <>
          <Col md={6} sm={12}>
            <Label htmlFor='warehouse_id' className='form-label text-uppercase'>
              Dokumen <span className='mandatory'>*</span>
            </Label>
            <Select
              required
              cacheOptions
              defaultOptions
              isClearable
              isSearchable={false}
              value={selectedDocument ?? ''}
              onChange={(v) => {
                setSelectedDocument(v)
              }}
              options={documentOptions}
            />
          </Col>
          <Col
            md={6}
            sm={12}
            style={{display: selectedDocument.date_required ? '' : 'none'}}
          >
            <ErrorFieldWrapper
              error={errors.tgl_document}
              message={errors.tgl_document?.message}
            >
              <Label
                htmlFor='tgl_document'
                className='form-label text-uppercase'
              >
                Tanggal <span className='mandatory'>*</span>
              </Label>
              <input
                id='tgl_document'
                name='tgl_document'
                defaultValue=''
                ref={tglDocumentRef}
                className='form-control dash-filter-picker shadow'
              />
            </ErrorFieldWrapper>
          </Col>
        </>
      ) : null}
      {docType === docTypes.PLP ? (
        <>
          <Col md={6} sm={12}>
            {izinBeacukaiInput}
          </Col>
          <Col md={6} sm={12}>
            <Label htmlFor='plp_date' className='form-label text-uppercase'>
              tanggal plp
            </Label>
            <input
              id='plp_date'
              name='plp_date'
              defaultValue=''
              ref={plpDateRef}
              className='form-control dash-filter-picker shadow'
            />
          </Col>
          <Col md={6} sm={12}>
            <Label htmlFor='ref_number' className='form-label text-uppercase'>
              no referensi
            </Label>
            <input
              id='ref_number'
              name='ref_number'
              defaultValue=''
              className='form-control'
              onChange={(e) =>
                setPlpValues((v) => ({...v, ref_number: e.target.value}))
              }
            />
          </Col>
        </>
      ) : null}

      {docType !== docTypes.PLP ? (
        <Col md={6} sm={12}>
          {izinBeacukaiInput}
        </Col>
      ) : null}

      <Col md={6} sm={12}>
        <ErrorFieldWrapper error={errors.awb} message={errors.awb?.message}>
          <Label htmlFor='awb' className='form-label text-uppercase'>
            Awb <span className='mandatory'>*</span>
          </Label>
          <Controller
            id='awb'
            name='awb'
            type='awb'
            control={control}
            render={({field}) => <Input {...field} value={field.value} />}
          />
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper error={errors.hawb} message={errors.hawb?.message}>
          <Label htmlFor='hawb' className='form-label text-uppercase'>
            HAWB
          </Label>
          <Controller
            id='hawb'
            name='hawb'
            type='hawb'
            control={control}
            render={({field}) => <Input {...field} value={field.value} />}
          />
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper error={errors.do_no} message={errors.do_no?.message}>
          <Label htmlFor='do_no' className='form-label text-uppercase'>
            DO
          </Label>
          <Controller
            id='do_no'
            name='do_no'
            type='do_no'
            control={control}
            render={({field}) => <Input {...field} value={field.value} />}
          />
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.warehouse_id}
          message={errors.warehouse_id?.message}
        >
          <Label htmlFor='warehouse_id' className='form-label text-uppercase'>
            Warehouse <span className='mandatory'>*</span>
          </Label>
          <Controller
            id='warehouse_id'
            name='warehouse_id'
            type='warehouse_id'
            control={control}
            render={({field: {onChange, ...field}}) => (
              <Select
                {...field}
                cacheOptions
                defaultOptions
                isClearable
                isSearchable={false}
                value={selectedWarehouse ?? ''}
                onChange={(v) => {
                  if (v?.value) onChange(v.value)
                  setSelectedWarehouse(v)
                }}
                options={warehouseOptions}
              />
            )}
          />
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.sender_name}
          message={errors.sender_name?.message}
        >
          <Label htmlFor='sender_name' className='form-label text-uppercase'>
            Nama Pengirim <span className='mandatory'>*</span>
          </Label>
          <Controller
            id='sender_name'
            name='sender_name'
            type='sender_name'
            control={control}
            render={({field}) => <Input {...field} value={field.value} />}
          />
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.sender_phone}
          message={errors.sender_phone?.message}
        >
          <Label htmlFor='sender_phone' className='form-label text-uppercase'>
            Nomor Pengirim <span className='mandatory'>*</span>
          </Label>
          <Controller
            id='sender_phone'
            name='sender_phone'
            type='sender_phone'
            control={control}
            render={({field}) => <Input {...field} value={field.value} />}
          />
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.sender_address}
          message={errors.sender_address?.message}
        >
          <Label htmlFor='sender_address' className='form-label text-uppercase'>
            Alamat Pengambilan Barang <span className='mandatory'>*</span>
          </Label>
          <Controller
            id='sender_address'
            name='sender_address'
            type='sender_address'
            control={control}
            render={({field}) => <Input {...field} value={field.value} />}
          />
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.receiver_name}
          message={errors.receiver_name?.message}
        >
          <Label htmlFor='receiver_name' className='form-label text-uppercase'>
            Nama Penerima <span className='mandatory'>*</span>
          </Label>
          <Controller
            id='receiver_name'
            name='receiver_name'
            type='receiver_name'
            control={control}
            render={({field}) => <Input {...field} value={field.value} />}
          />
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.receiver_phone}
          message={errors.receiver_phone?.message}
        >
          <Label htmlFor='receiver_phone' className='form-label text-uppercase'>
            Nomor Penerima <span className='mandatory'>*</span>
          </Label>
          <Controller
            id='receiver_phone'
            name='receiver_phone'
            type='receiver_phone'
            control={control}
            render={({field}) => <Input {...field} value={field.value} />}
          />
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.receiver_address}
          message={errors.receiver_address?.message}
        >
          <Label
            htmlFor='receiver_address'
            className='form-label text-uppercase'
          >
            Alamat Penerima <span className='mandatory'>*</span>
          </Label>
          <Controller
            id='receiver_address'
            name='receiver_address'
            type='receiver_address'
            control={control}
            render={({field}) => <Input {...field} value={field.value} />}
          />
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper error={errors.koli} message={errors.koli?.message}>
          <Label htmlFor='koli' className='form-label text-uppercase'>
            Koli <span className='mandatory'>*</span>
          </Label>
          <Controller
            id='koli'
            name='koli'
            type='koli'
            control={control}
            render={({field}) => <Input {...field} value={field.value} />}
          />
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.forwarder_id}
          message={errors.forwarder_id?.message}
        >
          <Label htmlFor='forwarder_id' className='form-label text-uppercase'>
            Agent / Forwader <span className='mandatory'>*</span>
          </Label>
          <Controller
            id='forwarder_id'
            name='forwarder_id'
            type='forwarder_id'
            control={control}
            render={({field: {onChange, ...field}}) => (
              <Select
                {...field}
                cacheOptions
                defaultOptions
                isClearable
                isSearchable={false}
                value={selectedForwarder ?? ''}
                onChange={(v) => {
                  if (v?.value) onChange(v.value)
                  setSelectedForwarder(v)
                }}
                options={forwarderOptions}
              />
            )}
          />
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.type_of_goods}
          message={errors.type_of_goods?.message}
        >
          <Label htmlFor='type_of_goods' className='form-label text-uppercase'>
            NATURE OF GOODS <span className='mandatory'>*</span>
          </Label>
          <Controller
            id='type_of_goods'
            name='type_of_goods'
            type='type_of_goods'
            control={control}
            render={({field}) => <Input {...field} value={field.value} />}
          />
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.weight}
          message={errors.weight?.message}
        >
          <Label htmlFor='weight' className='form-label text-uppercase'>
            Berat <span className='mandatory'>*</span>
          </Label>
          <Controller
            id='weight'
            name='weight'
            type='weight'
            control={control}
            render={({field}) => <Input {...field} value={field.value} />}
          />
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.flight_date}
          message={errors.flight_date?.message}
        >
          <Label htmlFor='flight_date' className='form-label text-uppercase'>
            Tanggal Penerbangan <span className='mandatory'>*</span>
          </Label>
          <input
            id='flight_date'
            name='flight_date'
            defaultValue=''
            ref={flyDateRef}
            className='form-control dash-filter-picker shadow'
          />
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.flight_code}
          message={errors.flight_code?.message}
        >
          <Label htmlFor='flight_code' className='form-label text-uppercase'>
            Kode Penerbangan <span className='mandatory'>*</span>
          </Label>
          <Controller
            id='flight_code'
            name='flight_code'
            type='flight_code'
            control={control}
            render={({field}) => <Input {...field} value={field.value} />}
          />
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.invoice_no}
          message={errors.invoice_no?.message}
        >
          <Label htmlFor='invoice_no' className='form-label text-uppercase'>
            Nomor Kwitansi <span className='mandatory'>*</span>
          </Label>
          <Controller
            id='invoice_no'
            name='invoice_no'
            type='invoice_no'
            control={control}
            render={({field}) => <Input {...field} value={field.value} />}
          />
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.invoice_date}
          message={errors.invoice_date?.message}
        >
          <Label htmlFor='invoice_date' className='form-label text-uppercase'>
            Tanggal Kwitansi <span className='mandatory'>*</span>
          </Label>
          <input
            id='invoice_date'
            name='invoice_date'
            defaultValue=''
            ref={kwitansiDateRef}
            className='form-control dash-filter-picker shadow'
          />
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.invoice_amount}
          message={errors.invoice_amount?.message}
        >
          <Label htmlFor='invoice_amount' className='form-label text-uppercase'>
            Jumlah dana Kwitansi
          </Label>
          <Controller
            id='invoice_amount'
            name='invoice_amount'
            type='invoice_amount'
            control={control}
            render={({field}) => <Input {...field} value={field.value} />}
          />
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.payment_method}
          message={errors.payment_method?.message}
        >
          <Label htmlFor='payment_method' className='form-label text-uppercase'>
            Payment Method <span className='mandatory'>*</span>
          </Label>
          <Controller
            id='payment_method'
            name='payment_method'
            type='payment_method'
            control={control}
            render={({field}) => (
              <Select
                {...field}
                cacheOptions
                defaultOptions
                isClearable
                isSearchable={false}
                value={selectedPaymentMethod ?? ''}
                onChange={(v) => {
                  if (v?.value) field.onChange(v.value)
                  setSelectedPaymentMethod(v)
                }}
                options={paymentMethods.filter(
                  (v) => v.value !== PAYMENT_METHODS.AGREEMENT,
                )}
              />
            )}
          />
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper error={errors.pu} message={errors.pu?.message}>
          <Label htmlFor='pu' className='form-label text-uppercase'>
            pu/pos
          </Label>
          <Controller
            id='pu'
            name='pu'
            type='pu'
            control={control}
            render={({field}) => <Input {...field} value={field.value} />}
          />
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper error={errors.pu} message={errors.pu?.message}>
          <Label htmlFor='caw' className='form-label text-uppercase'>
            caw
          </Label>
          <Controller
            id='caw'
            name='caw'
            type='caw'
            control={control}
            render={({field}) => <Input {...field} value={field.value} />}
          />
        </ErrorFieldWrapper>
      </Col>
    </Row>
  )
})

export default DataFormCDO
