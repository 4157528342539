import 'leaflet-routing-machine/dist/leaflet-routing-machine.css'
import L from 'leaflet'
import 'leaflet-rotatedmarker'
import 'leaflet-routing-machine'
import {useEffect} from 'react'
import {useMap} from 'react-leaflet'

export const degToRad = (degrees) => {
  return (degrees * Math.PI) / 180
}

export const radToDeg = (radians) => {
  return (radians * 180) / Math.PI
}

export const getBearing = (pointA, pointB) => {
  const lat1 = degToRad(pointA.lat)
  const lat2 = degToRad(pointB.lat)
  const lonDiff = degToRad(pointB.long - pointA.long)

  const y = Math.sin(lonDiff) * Math.cos(lat2)
  const x =
    Math.cos(lat1) * Math.sin(lat2) -
    Math.sin(lat1) * Math.cos(lat2) * Math.cos(lonDiff)
  let brng = radToDeg(Math.atan2(y, x))

  // Normalize the bearing to a positive value between 0 and 360 degrees
  brng = (brng + 360) % 360
  return brng
}
export const CreateLeafIcon = ({url}) => {
  return L.icon({
    iconUrl: url,
  })
}

export const RoutingAdd = ({waypoints}) => {
  const filteredWaypoints = waypoints.filter(({lat, long})=> lat && long)
  const map = useMap()
  useEffect(() => {
    if (!map) return
    L.Routing.control({
      waypoints: filteredWaypoints.map(({lat, long}) => L.latLng(lat, long)),
      show: false,
      addWaypoints: false,
      draggableWaypoints: false,
      lineOptions: {styles: [{color: '#5932EA', opacity: 1, weight: 5}]},
      router: new L.Routing.osrmv1({
        language: 'id',
        profile: 'car',
      }),
      plan: L.Routing.plan(
        filteredWaypoints.map(({ lat, long }) => L.latLng(lat, long)),
        {
          createMarker: (a, b, c) => {
            if (a === 1 && filteredWaypoints.length === 3) return false;
            return L.marker(b.latLng);
          },
        }
      )
    }).addTo(map)

  }, [map, waypoints])

  return null
}
